import React, { Component, Fragment } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import angleRight from "../images/common/angle-right-white.svg"
import feather from "../images/home/feather.png"
import xIcon from "../images/common/x.svg"
import Rodal from "rodal"
import "rodal/lib/rodal.css"
import seoContent from "../helpers/seoContent"
// import mailchimpSubscribe from "../helpers/subscribe"
import addToMailchimp from "gatsby-plugin-mailchimp"

export default class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newsletterEmail: "",
      newsletterEmailInvalid: false,
      newsletterLoading: false,
      newsletterModalOpen: false,
      showNewsletterSuccess: false,
      showNewsletterError: false,
      newsLetterSusbcribeError: "",
    }
  }

  updateEmail(e) {
    let { value } = e.target
    this.setState({
      newsletterEmail: value,
    })
  }

  validateEmail(val) {
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return regex.test(val)
  }

  async submitEmail(e) {
    e.preventDefault()
    const isValidEmail = this.validateEmail(this.state.newsletterEmail)

    if (isValidEmail) {
      this.setState({
        newsletterLoading: true,
      })
      // 1) submit to mailchimp
      // 2) start loading spinner
      // 3) open up modal (success or reject) when a mailchimp response is received
      // 4) stop loading spinner when modal opens
      // 5) if the subscription is successful then clear the state:
      // 5a) clear the email state
      // 5b) clear the invalid email state (makes red border when invalid email is submitted)
      const subscribeRes = await addToMailchimp(this.state.newsletterEmail)
      const mailchimpRes = subscribeRes && subscribeRes.result
      const mailchimpMsg = subscribeRes && subscribeRes.msg
      const isAlreadySubscribed =
        mailchimpMsg &&
        mailchimpMsg.includes("already subscribed") &&
        mailchimpRes === "error"
      const mailchimpSuccess = mailchimpRes === "success" || isAlreadySubscribed
      const mailchimpError = mailchimpRes === "error" && !isAlreadySubscribed

      if (mailchimpSuccess) {
        // subscription success
        this.setState({
          newsletterModalOpen: true,
          showNewsletterSuccess: true,
          showNewsletterError: false,
          newsletterEmail: "",
          newsletterEmailInvalid: false,
          newsletterLoading: false,
        })
      } else if (mailchimpError) {
        this.setState({
          newsletterModalOpen: true,
          showNewsletterError: true,
          newsLetterSusbcribeError: mailchimpMsg,
          showNewsletterSuccess: false,
          newsletterLoading: false,
        })
      } else {
        const subscribeError =
          "Error. Please try again or contact Gabriella directly through the contact page. Thank you!"
        this.setState({
          newsletterModalOpen: true,
          showNewsletterError: true,
          newsLetterSusbcribeError: subscribeError,
          newsletterLoading: false,
        })
      }
    } else {
      // 1) if email is not valid, don't send to mailchimp
      // 2) style the border to be red to show that the email is invalid
      this.setState({
        newsletterEmailInvalid: true,
      })
    }
  }

  closeNewsletterModal() {
    this.setState({
      newsletterModalOpen: false,
      newsletterEmail: "",
      newsletterEmailInvalid: false,
      newsletterLoading: false,
      showNewsletterSuccess: false,
      showNewsletterError: false,
      newsLetterSusbcribeError: "",
    })
  }

  renderNewsletterModalContent() {
    if (this.state.showNewsletterSuccess) {
      return (
        <Fragment>
          <div className="newsletter-content-title success">
            Subscribe Success!
          </div>
          <div className="newsletter-content-text">
            <p>Thank you for subscribing!</p>
            <p>You should receive a confirmation email shortly.</p>
          </div>
        </Fragment>
      )
    }
    if (this.state.showNewsletterError) {
      return (
        <Fragment>
          <div className="newsletter-content-title error">
            Oops! Something went wrong.
          </div>
          <div className="newsletter-content-text">
            <p>{this.state.newsLetterSusbcribeError}</p>
          </div>
        </Fragment>
      )
    }
  }

  render() {
    const validEmailStyle = {
      border: this.state.newsletterEmailInvalid ? "1px solid red" : "0px",
    }
    const { siteDescriptions } = seoContent
    return (
      <Layout>
        <SEO title="Home" description={siteDescriptions.home} />
        <div className="home">
          <div className="hero">
            <div className="hero-img">
              <div className="hero-cta">
                <div className="cta-title">Healing</div>
                <a
                  className="cta-btn"
                  target="_blank"
                  href="https://www.amazon.com/Healing-collection-poems-Gabriella-Alziari/dp/0578616513/"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy Now</span>
                  <img
                    className="arrow"
                    src={angleRight}
                    alt="angle-right-icon"
                  />
                </a>
                <div className="cta-subtitle">A collection of poems</div>
              </div>
            </div>
          </div>
          <div className="mobile-hero-cta-wrap">
            <div className="mobile-hero-cta">
              <div className="mobile-cta-title">Healing</div>
              <a
                className="mobile-cta-btn"
                target="_blank"
                href="https://www.amazon.com/Healing-collection-poems-Gabriella-Alziari/dp/0578616513/"
                rel="noopener noreferrer"
              >
                <span className="text">Buy Now</span>
                <img
                  className="arrow"
                  src={angleRight}
                  alt="angle-right-icon"
                />
              </a>
              <div className="mobile-cta-subtitle">A collection of poems</div>
            </div>
          </div>
          <div className="categories-wrap">
            <div className="category">
              <div className="category-text">Experience her poetry</div>
              <Link className="category-btn" to="/poems">
                <span className="btn-text">Click Here</span>
                <img
                  className="arrow"
                  src={angleRight}
                  alt="angle-right-icon"
                />
              </Link>
            </div>
            <div className="category">
              <div className="category-text">Sign up for Coaching</div>
              <Link className="category-btn" to="/coaching">
                <span className="btn-text">Click Here</span>
                <img
                  className="arrow"
                  src={angleRight}
                  alt="angle-right-icon"
                />
              </Link>
            </div>
            <div className="category">
              <div className="category-text">Travel through her photos</div>
              <Link className="category-btn" to="/photography">
                <span className="btn-text">Click Here</span>
                <img
                  className="arrow"
                  src={angleRight}
                  alt="angle-right-icon"
                />
              </Link>
            </div>
          </div>
          <div className="newsletter">
            <div className="title">Subscribe to the mailing list</div>
            <div className="subtitle">
              Stay in touch to learn more her new projects, poems, and events!
            </div>
            <form
              className="newsletter-signup"
              onSubmit={this.submitEmail.bind(this)}
            >
              <div className="input-wrap" style={validEmailStyle}>
                <input
                  placeholder="enter email"
                  type="text"
                  value={this.state.newsletterEmail}
                  onChange={this.updateEmail.bind(this)}
                />
              </div>
              <input
                className="submit-email"
                type="submit"
                value="Submit"
                onClick={this.submitEmail.bind(this)}
              />
            </form>
          </div>
          <div className="footer">
            <div className="learn-more section">
              <div className="title">Learn More</div>
              <div className="content">
                <Link className="learn-btn" to="/about">
                  <span>About Gabriella</span>
                  <img
                    className="arrow"
                    src={angleRight}
                    alt="angle-right-icon"
                  />
                </Link>
                <Link className="learn-btn" to="/faq">
                  <span>Read her FAQ</span>
                  <img
                    className="arrow"
                    src={angleRight}
                    alt="angle-right-icon"
                  />
                </Link>
                <Link className="learn-btn" to="/contact">
                  <span>Get in touch</span>
                  <img
                    className="arrow"
                    src={angleRight}
                    alt="angle-right-icon"
                  />
                </Link>
              </div>
            </div>
            <div className="follow section">
              <div className="title">Follow Her</div>
              <div className="content">
                <div className="social-media-wrap">
                  <a
                    className="social-media"
                    href="https://www.facebook.com/Gabriella-Alziari-533682247139232/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="social-media"
                    href="https://www.instagram.com/gabriellaalziari/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    className="social-media"
                    href="https://www.linkedin.com/in/gabbyalziari/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
                <img className="feather" src={feather} />
              </div>
            </div>
          </div>
          <Rodal
            visible={this.state.newsletterModalOpen}
            showCloseButton={false}
            onClose={this.closeNewsletterModal.bind(this)}
            className="newsletter-modal"
          >
            <img
              className="newsletter-modal-x-icon"
              src={xIcon}
              onClick={this.closeNewsletterModal.bind(this)}
            />
            <div className="newsletter-modal-content">
              {this.renderNewsletterModalContent()}
            </div>
          </Rodal>
          <div
            className="home-newsletter-loading-wrap"
            style={
              this.state.newsletterLoading
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <i className="home-newsletter-loading fas fa-yin-yang fa-spin"></i>
          </div>
        </div>
      </Layout>
    )
  }
}
